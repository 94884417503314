import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsAndConditionsPage = () => {
  return (
    <Layout>
      <SEO
        title="Implementing Inpowered's Tracking Pixel"
        description="Learn how to properly implement out tracking pixel."
        meta={[
          {
            name: "robots",
            content: "noindex, nofollow",
          },
        ]}
      />
      <section class="page-hero ">
        <div class="container has-text-centered">
          <h1 class="title has-text-centered has-text-weight-light mb-5 is-size-2 is-size-4-mobile	">
            <span>Terms and Conditions</span>
          </h1>
        </div>
      </section>

      <div className="blog-post-container content px-4">
        <p class="mb-5 is-medium">Updated on December 16, 2024.</p>
        <h2 className="is-size-4 has-text-link has-text-weight-medium mb-5 ml-2">
          1. Certain Definitions
        </h2>
        <p>1.1.“Agreement” means the Platform License Agreement.</p>
        <p>
          1.2 “Audience Tools” means the functionality provided in Content
          Distribution that enables advertising campaigns to be targeted to
          particular types of users (e.g., avid golfers). Audience Tools may
          utilize audience data provided by Customer (i.e., first party data),
          third parties (e.g., BlueKai) or inPowered. For clarity, any audience
          data provided by Customer shall constitute Customer Data.
        </p>
        <p>
          1.3 “Business and Campaign Data” means the data generated by
          Customer’s use of the Services (defined below) and the transactions
          under the Agreement. Business and Campaign Data does not include
          Customer Data (defined below) or inPowered Data (defined below).
        </p>
        <p>
          1.4 “Customer Data” means the data Customer imports into Content
          Distribution (defined below), including but not limited to information
          about advertising campaigns and Customer’s account. As described
          further in Section 5.1, Customer is the sole owner of Customer Data
          and inPowered may use the Customer Data solely to provide Services to
          Customer under the Agreement.
        </p>
        <p>
          1.5 “Fees” means the fees shown in the Agreement and the fees shown in
          the Content Distribution user interface for any additional optional
          features and services that Customer uses.
        </p>
        <p>
          1.6 “Inventory Partners” means the third parties who make inventory
          available for purchase through Content Distribution.
        </p>
        <p>
          1.7 “Total Media Spend” means the cost of all ad inventory purchased
          and/or data licensed by or on behalf of Customer through Content
          Distribution.
        </p>
        <p>
          1.8 “Services” means the services and functionality provided by
          Content Distribution, any campaign management services, tags and
          pixels, and any other services inPowered provides to Customer.
        </p>
        <p>
          1.9 “inPowered Data” means all data residing in Content Distribution
          that does not constitute Customer Data, Business and Campaign Data or
          third party data underlying the Audience Tools.
        </p>
        <p>
          1.10 “Total Cost” means Total Media Spend plus any applicable Fees.
        </p>
        <h2 className="is-size-4 has-text-link has-text-weight-medium mb-5 ml-2">
          2. Services
        </h2>
        <p>
          2.1 <strong>Self-Serve Usage</strong>. Subject to Customer’s
          compliance with the terms of the Agreement and as long as Customer has
          paid its fees in a timely fashion, inPowered grants Customer a
          non-exclusive, non-transferable right to access and use the Services
          on a self-serve basis, including for purposes of purchasing
          advertising and tracking and measuring content analytics. Customer
          will obtain and maintain (and hereby grants to inPowered to the extent
          necessary), all rights and permissions needed to buy inventory on
          Customer’s behalf, perform tracking and analytics, and to store and
          serve ads. inPowered may offer additional services beyond those
          described herein, including third party services. Fees for such
          services will be shown in the Content Distribution user interface or
          otherwise agreed to by the parties. In addition, to the extent that
          Customer requests inPowered’s help to use the Services, whether such
          requests are verbal or in writing, Customer consents to the actions
          that inPowered performs on its behalf. Customer retains sole
          responsibility for such assisted use of the Services.
        </p>
        <p>
          2.2 <strong>Reporting</strong>. Customer will have access to
          inPowered’s online reporting interface. All reports (and/or related
          data) from the interface are solely for the internal use of Customer.
          Reports and related data may not be shared with any third party other
          than to provide analytics services to Customer; provided, that such
          third party is contractually prohibited to utilize the reports, data
          or results therefrom for any purpose other than to provide the
          analytics services for Customer and must delete all copies of all data
          immediately thereafter. Customer shall be fully liable for the breach
          of the obligations of this Section by any third party.
        </p>
        <p>
          2.3 <strong>Campaign Analytics</strong>. inPowered offers analytics
          such as impression, click, engagement, time spent and conversion
          tracking (“Campaign Analytics”). This Section only applies if Customer
          implements the analytics code available in Content Distribution
          (“Campaign Analytics Code”). inPowered may append certain parameters
          to the URL associated with Customer’s ad to enable the Campaign
          Analytics. Customer may not edit or delete such parameters without
          inPowered’s prior written consent. Upon termination of the Agreement,
          Customer must promptly remove all Campaign Analytics Code. If Customer
          implements the Campaign Analytics Code, Customer acknowledges and
          agrees that inPowered may monitor interaction with its ads and
          website(s), inPowered may gather and use information from cookies
          and/or other automated technologies, but that inPowered does not
          collect information such as the user’s name or email address.
        </p>
        <p>
          2.4 <strong>Audience Tools</strong>. Fees for use of Audience Tools
          (e.g., fees for targeting based on third party audience data) will be
          as shown in Content Distribution or as agreed in advance in writing.
          Customer may use the Audience Tools solely for the purpose of managing
          campaigns through Content Distribution and shall not attempt to
          extract or recreate the data underlying the Audience Tools or assemble
          similar data or audience segments itself or from any different third
          party.
        </p>
        <p>
          2.5 <strong>Prohibitions</strong>. Customer will use the Services only
          for its own use. Customer will not (a) attempt to circumvent any
          Content Distribution security measure, (b) share its Content
          Distribution login or otherwise grant account access with any third
          party without the express permission of inPowered, (c) reproduce,
          distribute, modify, prepare derivative works of, translate, reverse
          engineer, reverse compile or disassemble any inPowered-provided tag,
          the Services or any portion thereof, (d) use or authorize the use of
          the Services for any purpose not permitted under the Agreement, (e)
          copy, sell, transfer, lease, lend, syndicate, or sub-syndicate the
          Services, or use the Services for co-branding, timesharing, arbitrage
          (e.g., reselling), service bureau or other unauthorized purposes, (f)
          utilize rotating ad tags or otherwise violate the Ad Standards, (g)
          import into Content Distribution any personally identifiable
          information including by using inPowered’s ad tags or pixels, or (h)
          distribute viruses or other malware (through the ads or any other
          mechanism). inPowered may immediately suspend Customer’s access or
          terminate the Agreement upon any breach by Customer of this Section
          2.6. In the event of a breach of this Section 2.6 that results in a
          termination or suspension of inPowered’s ability to conduct business
          with a third party partner (e.g., an inventory provider), without
          limiting any rights or remedies available to inPowered, and
          notwithstanding any other provision in the Agreement to the contrary,
          Customer shall be liable to inPowered for all lost profits resulting
          from suspension, which shall be added to Customer’s invoice. Inasmuch
          as the services inPowered provides are uniquely developed to fit the
          Customer’s particular needs, attempts to transfer any portion of the
          work to any third party could cause significant damage. Under no
          circumstances may Customer use the Services for gathering competitive
          intelligence. Customer may not create targeting profiles or segments
          on the basis of the publisher sites that it has visited, the content
          on the publisher’s page or site, or the general interest area(s)
          covered by the publisher (e.g., Customer may not create an “ESPN
          Audience” segment based on any information obtained during a prior
          campaign on ESPN properties).
        </p>
        <h2 className="is-size-4 has-text-link has-text-weight-medium mb-5 ml-2">
          3. Payment and Taxes
        </h2>
        <p>
          3.1 Unless the parties agree otherwise in writing, inPowered will
          invoice and Customer will pay in US dollars. If Customer chooses to
          pay its invoice via credit card, a 3% convenience fee shall be added
          to such amounts. All fees due are exclusive of sales, service, use,
          business and any similar taxes (collectively, “Transaction Taxes”).
          Customer shall self-assess any applicable Transaction Taxes to the
          extent required or allowed under applicable law. In the event that
          Customer does not self-assess any such Transaction Taxes, Customer
          shall indemnify and hold harmless inPowered against such Transaction
          Taxes and any applicable interest or penalties. If self-assessment is
          not required or allowed and inPowered is required to charge applicable
          Transaction Taxes to Customer, Customer shall pay to inPowered the
          total amount due on inPowered’s invoice, including any Transaction
          Taxes, in accordance with the payment terms set forth in the
          Agreement. In the event that inPowered does not charge such
          Transaction Taxes at the time of initial invoicing, but the relevant
          government authority determines that inPowered should have charged
          such Transaction Taxes, inPowered shall charge to Customer, and
          Customer shall pay to inPowered, any such Transaction Taxes as soon as
          practicable after such determination.
        </p>

        <h2 className="is-size-4 has-text-link has-text-weight-medium mb-5 ml-2">
          4. Counting
        </h2>
        <p>
          4.1 inPowered will have sole responsibility for calculating and
          reporting metrics on media or data bought and sold through Content
          Distribution and such metrics shall be used for calculating payments.
          inPowered will make such metrics available to Customer through Content
          Distribution. All Customer-trafficked ads will be counted as
          impressions. In the event of a discrepancy between Customer’s records
          and inPowered’s metrics, Customer may inspect the log files up to
          twice per calendar year, provided that any inspection shall be at
          reasonable times and on reasonable notice, and Customer may not
          inspect the log files for the same time period more than once. Any
          data (except for Customer Data) made available to Customer during an
          inspection is inPowered’s Confidential Information and Customer will
          not disclose such data to any third party or use such data for any
          purpose other than verifying inPowered’s accounting. Additionally,
          inPowered will make reasonable technological and auditing efforts to
          ensure inPowered end of the month figures are within 10% of the end of
          the month totals of supply-side counts. In the event that there is a
          discrepancy of more than 10% between the reported cost of inventory or
          data, based on a calendar month, and the Customer and inPowered are
          unable to resolve the discrepancy, inPowered is responsible for the
          amount of the total supply discrepancy above 10%. Customer must raise
          any discrepancies within 30 days of the date of the applicable
          invoice. inPowered may update numbers if there is delay in reporting
          affecting month-end invoicing.
        </p>

        <h2 className="is-size-4 has-text-link has-text-weight-medium mb-5 ml-2">
          5. Ownership and Data Usage
        </h2>

        <p>
          This Section describes the parties’ rights and obligations as to
          Customer Data, inPowered Data and Business and Campaign Data. Aside
          from any first party targeting data provided by Customer, which
          constitutes Customer Data, this Section does not cover the parties’
          rights and obligations as to any data underlying the Audience Tools
          (see Section 2.5 above).
        </p>

        <p>
          5.1 As between inPowered and Customer, (a) Customer owns all right,
          title and interest in all Customer Data and the ads Customer provides,
          and (b) inPowered owns all right, title and interest in the Services,
          including future developments and enhancements, and the inPowered
          Data. Aside from the rights granted herein, neither party grants the
          other any other right, express or implied, and each party reserves all
          rights not expressly granted hereunder.
        </p>

        <p>
          5.2 As to Customer Data, inPowered may use such data solely to provide
          the Services available under the Agreement. As to inPowered Data,
          Customer may use such data solely in connection with its use of
          Content Distribution and other Services. As to Business and Campaign
          Data, each party may use the data generated by Customer’s use of the
          Services as follows. Customer may use such data for any internal
          business purpose provided that (a) it complies with its privacy
          policy, (b) it complies with the terms of the Agreement and the
          Section herein including, but not limited to, Section 2.6, (c) it does
          not merge or combine any such data with any personally identifiable
          information or otherwise re-identify any individual, and (d) it does
          not disclose data that describes or reflects the performance of
          Content Distribution (or any other Services), or any publisher or
          inventory partner, to third parties except service providers who are
          under confidentiality restrictions. inPowered may use such data (a) to
          provide the Services available under the Agreement, (b) internally for
          any other business purpose (e.g., fraud detection or financial
          reporting), and (c) externally if such data is aggregated with other
          data such that third parties cannot attribute the data to Customer
          (e.g., publishing industry trends on average CPEs). Customer
          acknowledges that Inventory Partners may have access to information
          about purchasers of their inventory.
        </p>

        <p>
          5.3 Any suggestions, comments, improvements, ideas, enhancement
          requests or feedback provided by Customer relating to Content
          Distribution, the Services or any other services or products of
          inPowered, may be voluntarily provided and Customer agrees that each
          may be used by inPowered without compensation, accounting or
          attribution to Customer, and Customer grants a perpetual, irrevocable,
          fully paid up right and license to any of the foregoing.
        </p>

        <h2 className="is-size-4 has-text-link has-text-weight-medium mb-5 ml-2">
          6. Compliance
        </h2>
        <p>
          6.1 Both parties will comply with all applicable laws, rules,
          regulations and government guidance (inPowered in its provision of
          Content Distribution and Services in the form provided, and Customer
          as to the ads it provides and its use of Content Distribution and the
          Services), which shall include, for purposes of clarification and not
          of limitation, FTC guidance on sponsorships and native advertising.
          Customer will comply with inPowered’s Ad Standards and security
          requirements and with any applicable Inventory Partner ad standards
          provided to Customer (all of the foregoing, “Ad Standards”). Customer
          will not use the Services in connection with any ads (or the targeting
          thereof) or other digital content that are obscene or pornographic, or
          that depict illegal activity. In addition, Customer will not use the
          Services in connection with any ads that violate any law, regulation
          or third party right (including intellectual property and privacy
          rights) or that are deceptive or defamatory. Without limiting
          Customer’s obligations under the Children’s Online Privacy Protection
          Act (“COPPA”), Customer shall not use any persistent identifier to
          target users of any inventory (e.g., websites, apps or other online
          properties) directed or targeted at children under age 13 or that
          Customer knows or should know are used by a substantial or
          disproportionately high ratio of children under age 13. inPowered may
          immediately suspend any campaign, Customer’s use of Content
          Distribution and the Services or terminate the Agreement if Customer
          violates this Section 6.1.
        </p>

        <p>
          6.2 inPowered may reject any ads that do not comply with the
          applicable Ad Standards. inPowered may immediately suspend any
          Customer campaigns if inPowered reasonably determines that Customer or
          any ad provided by Customer has failed to comply with the Agreement or
          the applicable Ad Standards, the guidelines or requirements provided
          by an ad exchange or other Inventory Partners or has used the Services
          in a manner that could damage Content Distribution or reflect
          unfavorably upon inPowered, its affiliates, or Inventory Partners.
          inPowered will promptly notify Customer in writing upon any such
          suspension. If Customer fails to cure its non-compliance within 10
          days of notice or Customer repeatedly fails to comply, inPowered may
          immediately terminate the Agreement upon written notice to Customer.
        </p>

        <h2 className="is-size-4 has-text-link has-text-weight-medium mb-5 ml-2">
          7. Mutual Indemnification
        </h2>

        <p>
          inPowered shall indemnify, defend and hold harmless Customer and its
          directors, officers, employees and agents (and successors, heirs and
          assigns) (the “Customer Parties”) against any liability, damage, loss
          or expense (including reasonable attorneys’ fees and costs)
          (“Liabilities”) incurred by the Customer Parties in connection with
          any third-party claim that inPowered’s proprietary technology that
          provides the Services, in the form provided by inPowered, infringes
          any US patent or other third party intellectual property right.
          Customer shall indemnify, defend and hold harmless inPowered and its
          directors, officers, employees and agents, its and their respective
          successors, heirs and assigns (the “inPowered Parties”) against any
          Liabilities incurred by the inPowered Parties in connection with any
          third-party claim arising out of or relating to (a) Customer’s use of
          Content Distribution or the Services, (b) any advertisement or other
          material with which Customer uses Content Distribution (including the
          ads, landing pages and other materials of Customer and Customer’s
          clients), and (c) the breach of any obligations or restrictions in
          this Agreement by any advertiser or third party partner of Company.
          The indemnified party will provide the indemnitor with prompt notice
          of any claim (provided that the failure to promptly notify shall only
          relieve indemnitor of its obligation to the extent it can demonstrate
          material prejudice from such failure) and at the indemnitor’s expense,
          provide assistance reasonably necessary to defend such claim. The
          indemnitor will not enter into a settlement that would result in
          liability to the indemnified party without the indemnified party’s
          prior written consent, which shall not be unreasonably withheld or
          delayed.
        </p>

        <p>
          7.1 inPowered shall have no obligation for any claim arising from or
          relating to (a) compliance with Customer specifications, (b) any
          combination of the Services and/or Business and Campaign Data with
          products or services not supplied by inPowered, where such
          infringement would not have occurred but for such combination, (c)
          adaptation or modification of the Services or Business and Campaign
          Data, where such infringement would not have occurred but for such
          adaptation or modification, (d) Customer’s failure to follow
          instructions provided by inPowered which would have cured the
          infringement, provided that following such instructions would not have
          caused Customer substantial additional cost, (e) use of the Services
          or Business and Campaign Data other than as described in Sections 2
          and 5 above, or (f) Customer’s continued use of a version of Content
          Distribution other than the most recently released version, where such
          infringement would not have occurred if such most recently released
          version had been used. If any of the subsections above apply, Customer
          will indemnify, defend and hold harmless inPowered and its officers,
          directors, employees, and agents from and against any Liabilities
          arising directly or indirectly out of such claims.
        </p>

        <p>
          7.2 If Content Distribution or the Services are held in a legal action
          or proceeding to infringe any intellectual property rights of a third
          party and the use of Content Distribution or the Services is enjoined,
          or inPowered reasonably believes that it is likely to be found to
          infringe or likely to be enjoined, then inPowered may, at its sole
          cost, expense, and option, either (a) procure the right to continue
          using Content Distribution or the Services, or (b) modify Content
          Distribution or the Services so that it becomes non-infringing without
          affecting the basic functionality of Content Distribution or the
          Services; provided, however, that if (a) and (b) are not practicable,
          inPowered may, in its sole discretion, terminate the Agreement with
          respect to Content Distribution or the Services by giving Customer 30
          days written notice, upon which termination inPowered shall refund the
          portion of such pre-payment that reflects the remaining term.
          inPowered’s obligations as stated herein are Customer’s sole remedy
          and inPowered’s sole liability arising out of or relating to
          infringement claims.
        </p>

        <h2 className="is-size-4 has-text-link has-text-weight-medium mb-5 ml-2">
          8. Miscellaneous
        </h2>

        <p>
          8.1 Neither party will make any public statement relating to the
          Agreement without the prior written approval of the other, except that
          inPowered may include Customer’s name and logo in its marketing,
          promotional materials, and customer lists.
        </p>

        <p>
          8.2 inPowered reserves the right to continually evolve Content
          Distribution and its Services without notice. In the event inPowered
          chooses to discontinue the Content Distribution, inPowered will
          provide Customer with 30 days prior notice. inPowered will provide
          such notice of discontinuation when Customer logs into Content
          Distribution, on the inPowered website or by email, and the notice
          shall be effective immediately.
        </p>

        <p>
          8.3 A party’s failure or delay to exercise any right will not operate
          as a waiver, nor will any single or partial exercise of any such right
          preclude any other exercise or the exercise of any other right, power,
          or remedy.
        </p>
      </div>
    </Layout>
  )
}

export default TermsAndConditionsPage
